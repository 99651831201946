
import { UPDATE_LOGON_MYACCOUNT } from '@store/mutations/my-account'
import { ACTION_UPDATE_SEARCH_OVERLAY_OPEN } from '@store/actions/search'
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../i18n-setup'
import { getCurrentCountry } from '@libs/utils/currentCountry'
import { getUserType } from '@libs/wcs/my-account'
import sghNavigationRightPart from './navigation-right-part.vue'
import SghMainNavigation from '../navigation/main-navigation.vue'
import { ACTION_UPDATE_MOBILE_MENU_STATUS } from '@store/actions/ui'
import { ACTION_UPDATE_CMSPLACEMENTS } from '@store/actions/ddm'
import SubMenusOutlet from '@components/navigation/sub-menus-outlet.vue'

const { mapMutations } = createNamespacedHelpers('myAccount')
const { mapGetters: mapGettersWishlist } = createNamespacedHelpers('wishlist')
const { mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')
const { mapActions: mapActionsSearch } = createNamespacedHelpers('search')
const { mapActions: mapActionsUI, mapGetters: mapGettersUi } = createNamespacedHelpers('ui')
const { mapActions: mapActionsDdm, mapGetters: mapGettersDdm } = createNamespacedHelpers('ddm')
const { mapGetters: mapGettersMinicart } = createNamespacedHelpers('minicart')

export default {
  name: 'SghHeaderNavigation',
  components: {
    SubMenusOutlet,
    SghMainNavigation,
    sghNavigationRightPart,
  },
  props: {
    carturl: {
      default: '',
      type: String,
    },
    searchEnabled: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      scrollY: 0,
      subMenuOpen: false,
      innerHeight: window.innerHeight,
      getHeightMenu: 0,
      ddmLoaded: false,
    }
  },
  watch: {
    'menu.mobileMenuOpen':function(value) {
      document.body.classList.toggle('menu-opened',value)
      document.body.style.overflow = value ? 'hidden' : 'auto'
    },
  },
  created() {
    if (!this.$router) {
      getUserType().then(userType => {
        this.updateLoginStatus(userType === 'R')
      })
    }

    const placementDdm = this.isMobile ? 'asNavigationMobMain' : 'asNavigationDtMain'
    this.updateDdmPlacements({ view: placementDdm }).then((responseData) => {
    const name = 'ddmPlacement'
    const device = this.isMobile ? 'mobile' : 'desktop'
    const ddmName = `${name}_${device}${window.algoliaConfig.isEarlyAccessParam ? 'EA' : ''}`
    sessionStorage.setItem(`${ddmName}_${getCurrentCountry()}`, JSON.stringify(responseData))
      this.ddmLoaded = true
})
  },

  mounted() {
    loadSectionTranslations('my-account-new')
    loadSectionTranslations('cms')
    loadSectionTranslations('plp')
     window.addEventListener('scroll', event => {
      this.scrollY = scrollY
      this.getHeightMenu = this.$refs.sghmenu.clientHeight
    })
    window.addEventListener('resize', () => (this.innerHeight = window.innerHeight))
    this.$root.$on('submenuOpen', value => (this.subMenuOpen = value))
  },
  computed: {
    ...mapGettersWishlist(['counterWishlist']),
    ...mapGettersMyAccount(['profileDetails']),
    ...mapGettersUi(['menu']),
    ...mapGettersDdm(['mainMenu']),
    ...mapGettersMinicart(['numberItems']),
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
    getWishlistUrl() {
      return `/${getCurrentCountry()}/wishlist`
    },

    currentCountry() {
      return getCurrentCountry()
    },
    goToHome() {
      return `/${getCurrentCountry()}`
    },
    getTopMain() {
      const menu = this.$refs.sghmenu ? this.$refs.sghmenu : null
      return menu ? menu.getAttribute('top') : 0
    },
    menuStyle() {
      return {
        '--vh': `${this.innerHeight * 0.001}rem`,
      }
    },
    quantityWishlist() {
      return this.counterWishlist
    },
    registrationApprovalStatusBoolean() {
      return (
        this.profileDetails?.objectPerson?.personalInfo?.registrationApprovalStatus === 'Approved'
      )
    },
    numberOfItemsInCart() {
   const storedCartItems = +localStorage.getItem('SGH_CARTITEMS')
   return storedCartItems > 0 ? storedCartItems : this.numberItems
  },
    isLoyaltyEnabled() {
      return sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
    },
    mobileSubMenuPanelVisible() {
      return this.menu.mobileSubMenuPanelOpen
    },
  },
  methods: {
    ...mapMutations({
      updateLoginStatus: UPDATE_LOGON_MYACCOUNT,
    }),
    ...mapActionsSearch({
      updateOverlayIsOpen: ACTION_UPDATE_SEARCH_OVERLAY_OPEN,
    }),
    ...mapActionsUI({
      toggleMobileMenu: ACTION_UPDATE_MOBILE_MENU_STATUS,
    }),
    ...mapActionsDdm({
      updateDdmPlacements: ACTION_UPDATE_CMSPLACEMENTS,
    }),
    toggleMenu() {
      this.innerHeight = window.innerHeight
      this.$refs.menu.style.setProperty('--vh', `${this.innerHeight * 0.001}rem`)
      this.toggleMobileMenu()
    },
    toggleFakeInput(flag) {
      if (flag) {
        document.body.classList.add('fake-input-active')
        if (document.body.classList.contains('fake-input-active')) {
          setTimeout(() => {
            this.updateOverlayIsOpen(true)
          }, 1000)
        }
      } else {
        document.body.classList.remove('fake-input-active')
      }
    },
  },
}
